.App {
  background-color: var(--primary-color);
  text-align: center;
  font-family: var(--font-base);
}

.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: center; /* Add this line */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.LogoTaxi {
  height: 40vmin;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* ----------------------------------- */
/* f o n t s */
/* ----------------------------------- */


.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}


.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;
  text-transform: uppercase;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}



/* ----------------------------------- */
/* CSS za Wraper i popratne components */
/* ----------------------------------- */

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright {
  width: 100%;
  padding: 2rem 0 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }
}

/* ----------------------------------- */
/* Navigation Dots                     */
/* ----------------------------------- */

.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--link-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .app__navigation {
    display: none;
  }


  .copyright {
    padding: 2rem;
  }
}


::-webkit-scrollbar{
  display: none;
}


/* ----------------------------------- */
/* Social Icons                        */
/* ----------------------------------- */
.app__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  .social__Icons  {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--lightGray-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }



      svg {
        color: var(--white-color);
      }
    }

    :hover {
      background-color: var(--link-color);
      border-color: var(--link-color);

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}


@media screen and (max-width: 500px) {
  .app__social{
    display: none;
  }
  .copyright {
    padding: 2rem;
  }
}


::-webkit-scrollbar{
  display: none;
}


.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width:1px ;
  -webkit-text-stroke-color:white ;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.hero__text{
  font-size:xx-large ;
  justify-content: center;
  align-items: center;  
}





