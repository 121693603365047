#home {
  display: flex;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;  
    // disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }
  
  .app__header {
    background-color: var(--primary-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

  
    @media screen and (min-width: 2000px) {
      padding-top: 8rem;
    }
  
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  
    @media screen and (max-width: 450px) {
      padding: 6rem 1rem 2rem;
    }
  }
  
  .app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  
    margin: 0 2rem;
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-right: 0rem;
    }
  }
  
  .app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

   

  
    .badge-cmp,
    .tag-cmp,
    .direction-cmp {
      padding: 1rem 2rem;
      background: var(--white-color);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
  
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
  
    .tag-cmp {
      padding: 1rem 2rem;
      background: var(--white-color);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
  
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

      //flex-direction: column;
      margin-top: 2rem;
  
      p {
        width: 100%;
        text-transform: uppercase;
        text-align: right;
      }
    }

    .direction-cmp {
      padding: 1rem 2rem;
      background: var(--white-color);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
  
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

      flex-direction: column;
      margin-top: 2rem;
  
      p {
        width: 100%;
        text-transform: uppercase;
        text-align: right;
      }
    }
  
    span {
      font-size: 2.5rem;
  
      @media screen and (min-width: 2000px) {
        font-size: 5rem;
      }
    }
  
    @media screen and (max-width: 1200px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  .app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin: 1rem;
  
    div {
      width: 100px;
      height: 100px;
      border-radius: 50%;
  
      background: transparent;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 1);

        
      }
    }
  
    // circles with different height & width
  
    div:nth-child(1) {
      width: 100px;
      height: 100px;
    }
  
    div:nth-child(2) {
      margin: 1.75rem;
      width: 150px;
      height: 150px;
    }
  
    div:nth-child(3) {
      width: 70px;
      height: 70px;
    }
  
    @media screen and (min-width: 2000px) {
      div:nth-child(2) {
        width: 400px;
        height: 400px;
      }
  
      div:nth-child(3) {
        width: 170px;
        height: 170px;
      }
  
      div:nth-child(1) {
        width: 200px;
        height: 200px;
      }
    }
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 0;
  
      div {
        margin: 1rem;
      }
    }
  }
  
  .app__header-img {
    flex: 1;
    height: 100%;
  
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
  
    img {
      width: 100%;
      object-fit: contain;
      z-index: 1;
    }
  
    .overlay_circle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 100%;
      height: 90%;
    }
  
    @media screen and (max-width: 1200px) {
      margin: 2rem 0;
    }
  }


  /*BROJAĆI*/
.brojaci{
  display: flex;
  justify-content: center;
  gap:2.5rem;
  margin-top: 6rem;
  background: var(--lightGray-color);
  padding: 1rem 2rem;
  border-radius: 15px;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}
.brojaci>div{
  display: flex;
  flex-direction: column;
}
.brojaci>div>span:nth-of-type(1){

  color: black;
  font-size: 1.5rem;
  font-weight: 800;

}
.brojaci>div>span:nth-of-type(2),
.brojaci>div>span:nth-of-type(3){
  font-size: 0.9rem;
  text-align: center;
  color: gray;
  line-height: 1.2;
  text-transform: uppercase;
}

@media screen and (max-width: 765px) {
  .brojaci{
    gap:1.5rem;
    padding: 0.5rem 0.5rem;
    font-size: x-large;
  }
  .brojaci>div>span:nth-of-type(1){
    display: flex;
    font-size: x-large;
    justify-content: center;
    padding: 0.5rem;
  
  }
  .brojaci>div>span:nth-of-type(2),
  .brojaci>div>span:nth-of-type(3){
    font-size: small;
}
}

.construction {
  margin-top: 2rem;
  gap: 2rem;
}



/*
 I N F O 
*/
.info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0.7rem;
}

.info__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align:center;
  width: 100%;
  margin-bottom: 1rem;
}

.LogoTaxi {
  width: 40vw; /* Adjust the width as needed */
  height: auto; /* The height will scale proportionally with the width */
  max-width: 100%; /* Ensures the image doesn't exceed its container's width */
  display: block; /* Ensures the image behaves as a block element */
}

img {
  width: 10vw;
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .info {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .info__block {
    width: auto;
    margin-bottom: 0;
  }
}



.whatsapp_icon_big {
  display: flex;
  justify-content: center;
  color: greenyellow;
  font-size: 3rem;
}

.pozivi {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


//Let's start klizeći info
.letsStart {
  margin-top: 1rem;
  background-color: var(--lightGray-color);
  border-radius: 4rem;
  width: fit-content;
  padding: 1.25rem 0.8rem;
  text-transform: uppercase;
  color: black;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.letsStart>span{
z-index: 2;
letter-spacing: 1px;
font-size: 0.9rem;

}
.letsStart>div{
  position: absolute;
  background-color: greenyellow;
  width: 5.4rem;
  height: 80%;
  border-radius: 3rem;
  left: 8px;
  z-index: 1;
}



