#taxi {
  display: flex;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;  
    // disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }


.app__taxi {
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}


.app__testimonial-item {
  width: 60%;
  min-height: 100px;
  margin:0.5rem;
  background-color: var(--white-color);
  display: flex;
  flex-direction: row;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

  transition: all 0.3s ease-in-out;
  gap: 2rem;

  img {
    width: 100px;
    height: 100px;
    border-radius: 0;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    min-height: 450px;

    img {
      width: 150px;
      height: 150px;
    }
  }

  @media screen and (max-width: 850px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}


//-------------------------------------
//-------------------------------------
//-------------------------------------
//-------------------------------------
.Taxi_App {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Taxi__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Taxi__card {
  width: 100%;
  max-width: 400px;
  margin: 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  background-color: yellow;
}

.Taxi__card-image {
  height: 300px;
  overflow: hidden;
}

.Taxi__card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Taxi__card-content {
  padding: 1rem;
}

.Advertising__card-content h3 {
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
}

.Advertising__card-content p {
  margin: 1rem 0;
}
.Advertising__card-content>p>span {
  font-size: 1.3rem;
  margin: 1rem 0;
  padding: 0.5rem;
  font-weight: bold;
}

.Advertising__card-content a {
  display: inline-block;
  background-color: #005aaa;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
}

.Advertising__card-content a:hover {
  background-color: #004e8c;
}

//-----------------------------------
//-----------------------------------
//-----------------------------------
//-----------------------------------
  /*BROJAĆI*/
  .Taxi_card{
    display: flex;
    width:  85vw;
    justify-content: space-between;
    flex-direction: row;
    gap:1rem;
    margin-top: 2rem;
    background: var(--lightGray-color);
    padding: 0.7rem 0.7rem;
    border-radius: 15px;  
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }

  .taxi_card_image{
    width: 9vw;
    height: 9vw;
  }




  .whatsapp_icon,
  .phone_icon{
    background-color:var(--lightGray-color);
    align-items: center;
  }

  .whatsapp_icon{
    color: rgb(2, 246, 2);
  }
  
  .aaa{
    display: flex;
    align-items: center;
    color: var(--primary-color);
    font-size: 40px;
  }
